import React from 'react'

import totvsLogo from './images/totvs.png'
import rocketIcon from './images/rocket-icon.png'
import cadeadoIcon from './images/cadeado.png'
import targetIcon from './images/alvo-icon.png'
import linkedinIcon from './images/linkedin.svg'
import facebookIcon from './images/facebook.svg'
import instagramIcon from './images/instagram.svg'
import youtubeIcon from './images/youtube.svg'
import closeIcon from './images/close.svg'

import authorPhoto from './images/author-photo.jpeg'
import linkedinAuthor from './images/linkedin-author.svg'
import calendar from './images/calendar.png'
import check from './images/check.png'

import Card from "./components/Card"
import Forms from './components/Forms'

import './App.css'

function App() {

  return (
    <div className="container-page">
      <div className="modal-container">
                <div className="modal-subcontainer">
                    <div className="modal-close">
                        <button className="modal-close-btn"
                            onClick={() => {
                                const modal: any = document.querySelector('.modal-container')
                    
                                modal.style.display = 'none'
                        }}>
                          <img src={closeIcon} alt="" />
                        </button>
                    </div>
                    <Forms />
                </div>
            </div>
      <section className="main-content-container">
        <header className="header-page">
          <div className="logo-absolute-container">
            <img src={totvsLogo} alt="" />
          </div>
        </header>
        <main className="first-block-container">
          <div className="first-block-container-itens">
            <div className="first-block-text-container">
              <h1>Gestão de Processos Industriais de Bioenergia: <br/><span>
              o que você sempre quis saber e não tinha ninguém para perguntar! </span></h1>
              <p>
              Descubra novos meios de produzir combustíveis e outros agentes energéticos reduzindo os impactos ambientais e tendo um melhor aproveitamento de matérias-primas.              </p>
              <div className="info-event-container">
                <div className="info-event">
                  <img src={calendar} alt="" />
                  <p>13/08, às 10h.</p>
                </div>
                <div className="info-event">
                  <img src={check} alt="" />
                  <p>100% online e gratuito.</p>
                </div>
              </div>
                <button onClick={() => {
                  const modal: any = document.querySelector('.modal-container')
                      
                  modal.style.display = 'flex'
                }} className="first-block-btn">
                  Quero me inscrever!  
                </button>
            </div>
            <div className="first-block-asset-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/neolneNUNWA" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
            </div>
          </div>
        </main>
        <div className="additional-height"></div>
      </section>

      <h2>O que você vai aprender no evento:</h2>
      <section className="second-block-container">
        <Card 
          image={targetIcon}
          title="Controle analítico do processo"
          text="Aprenda o controle da produção intermediária e final, controle o consumo de insumo, o setup de produção e monitore a parada de equipamentos. "
        />
        <Card 
          image={cadeadoIcon}
          title="Cálculo de rendimentos e eficiências"
          text="Entenda as configurações de variáveis e fórmulas, de boletins e visões gerenciais, aprenda também os planos e metas."
        />
        <Card 
          image={rocketIcon} 
          title="Automatização da coleta de análise"
          text="Realize o controle estatístico de processo, certificados de produtos, controle de qualidade de acordo com as normas e procedimentos e controle de laboratório."
        />
      </section>

      <div className="line-break"> </div>


      <h2>Quem apresentará o evento:</h2>

      <section className="author-container">
        <div className="author-image-container">
          <img src={authorPhoto} alt="Fábio Turati" />
        </div>
        <div className="author-description">
          <div className="author-social-medias">
            <a href="https://www.linkedin.com/in/sergiogferreira/" target="_blank" rel="noopener noreferrer">
              <img src={linkedinAuthor} alt="Linkedin" />
            </a>
            <h3>Sérgio Gomes</h3>
          </div>
          <p>
          Sérgio Gomes Ferreira é especialista em Gestão de Tecnologia da Informação, cursando MTA em Gestão Industrial Sucroenergética na Universidade Federal de São Carlos e graduado em Análise e Desenvolvimento de Sistemas. Hoje, atua como Analista de Implantação TOTVS PIMS.
          </p>
        </div>
      </section>
      

      <button onClick={() => {
                const modal: any = document.querySelector('.modal-container')
                    
                modal.style.display = 'flex'
              }} className="first-block-btn final-page-btn">
                Quero me inscrever! 
      </button>

      <footer className="footer-page">
        <div className="footer-logo-container">
          <img src={totvsLogo} alt="" />
        </div>
        <div className="footer-social-medias-container">
          <h4>Nos acompanhe nas redes sociais</h4>
          <div className="footer-social-medias">
            <a href="https://www.linkedin.com/company/totvsbrasilcentralagro/mycompany/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="Linkedin" />
            </a>
            <a href="https://facebook.com/totvs" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/totvsbrasilcentralagro/" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UCkSBkhhEhcN-_Vi6AYY26pQ" target="_blank" rel="noopener noreferrer">
              <img src={youtubeIcon} alt="Youtube" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
