import React, { useState } from 'react'

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import api from '../../services/api'
import { HandleSubmitOnFisrtStep, handleSendData } from '../../services/submitApi'

import confirmImage from '../../images/confirm.svg'
import whatsappIcon from '../../images/whatsapp.svg'

import './styles.css'

function Forms() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [formStep, setFormStep] = useState('1')

    const [occupation, setOccupation] = useState('')
    const [specification, setSpecification] = useState([])

    const [billValue, setBillValue] = useState('')
    const [loading, setLoading] = useState<boolean>()

    const animatedComponents = makeAnimated();
  
    const bill: string[] = [
        "Valor em Milhões de R$",
        "<=15",
        "15>=75",
        "75>=300",
        "300<850",
        ">850" 
    ]

    const prodRural = [
        { value: 'grãos', label: 'Grãos' },
        { value: 'algodão', label: 'Algodão' },
        { value: 'café', label: 'Café' },
        { value: 'frutas', label: 'Frutas' },
        { value: 'cana de açúcar', label: 'Cana de Açúcar' },
        { value: 'avicultura', label: 'Avicultura' },
        { value: 'suinocultura', label: 'Suinocultura' },
        { value: 'pecuária', label: 'Pecuária' },
    ]
    const manuAgro = [
        { value: 'Indústria de Insumos e Defensivos', label: 'Indústria de Insumos e Defensivos' },
        { value: 'Indústria de Máquinas e Implementos', label: 'Indústria de Máquinas e Implementos' },
        { value: 'Genética', label: 'Genética' }
    ]
    const rev = [
        { value: 'insumos e defensivos agrícolas', label: 'Insumos e Defensivos Agrícolas' },
        { value: 'máquinas e implementos', label: 'Máquinas e Implementos' }
    ]
    const agroInd = [
        { value: 'Usinas e Destilarias', label: 'Usinas e Destilarias' },
        { value: 'Óleos vegetais', label: 'Óleos vegetais' },
        { value: 'Fábrica de ração', label: 'Fábrica de ração' },
        { value: 'Beneficiamento de Sementes', label: 'Beneficiamento de Sementes' },
        { value: 'Beneficiamento de Algodão', label: 'Beneficiamento de Algodão' },
        { value: 'Frigorífico', label: 'Frigorífico' }
    ]
    const prestServ = [
        { value: 'Contadores', label: 'Contadores' },
        { value: 'Agrônomos', label: 'Agrônomos' },
        { value: 'Veterinários', label: 'Veterinários' },
        { value: 'Zootecnista', label: 'Zootecnista' },
        { value: 'Consultores', label: 'Consultores' }
    ]
    const ocupacao: string[] = [
        "Selecione uma opção",
        "Produtor Rural",
        "Cooperativa Agropecuária",
        "Manufatura para o Agro",
        "Revenda",
        "Armazéns Cereais",
        "Trading",
        "Agroindústria",
        "Prestadores de Serviços",
        "Outros"
    ]

    async function SubmitDatasForApiFinalStep(email: string) {
        setLoading(true)
        api({
          params: {
            jsoncallback: " ",
            token_api_lahar: "totvsagrijmmJFNL184O7n1dLqnLgGF3y4ZluUIcWAB72qlQcQ9RBYO70EcV7iLM",
            email_contato: email,
            nome_conversao: "BIOENERGIA | ETAPA 3",
            tipo_integracao: "conversions",
            nome_formulario: "BIOENERGIA | ETAPA 3",
            GbMh954KPa: billValue
          }
        })
          .then(res => {
            console.log(res)
            setLoading(false)
            if (res.data.status === "sucesso") {
              setFormStep('4')
            } else {
              alert('Houve um erro ao salvar os dados, tente novamente. :(')
            }
          })
          .catch(err => alert('Houve um erro ao salvar os dados, tente novamente. :('))
    }
    
    async function handleToDatasValidatorFinalStep(e: any) {
        e.preventDefault()
        if (billValue === "" || billValue === "Selecione...") {
          alert("Selecione uma opção")
        } else {
          if (!email || email === "") {
            alert('email invalido')
          } else {
            SubmitDatasForApiFinalStep(email)
          }
        }
    }

    function submitSpecificationToApi(formName: any, fieldToken: any, email: any): void {

        let subOccupation: any = []
        specification.map((value: any) => {
        subOccupation = [...subOccupation, value.value]
        })
        subOccupation = subOccupation.toString().replace(/,/g, ';')  

        api({
        params: {
            jsoncallback: " ",
            token_api_lahar: "totvsagrijmmJFNL184O7n1dLqnLgGF3y4ZluUIcWAB72qlQcQ9RBYO70EcV7iLM",
            email_contato: email,
            nome_conversao: "BIOENERGIA | ETAPA 2 B",
            tipo_integracao: "conversions",
            nome_formulario: "BIOENERGIA | ETAPA 2 B",
            [fieldToken]: subOccupation
        }
        })
        .then(res => console.log('success'))
        .catch(err => alert('Houve um erro ao salvar os dados, tente novamente. :('))
    }

    async function SubmitDatasForApi(email: string) {
        await handleSendData("Você atua em qual segmento agro?", "OrtW7qUVWz", occupation, email)

        
        if (occupation === "Produtor Rural" || occupation === "Manufatura para o Agro" || occupation === "Revenda" || occupation === "Agroindústria" || occupation === "Prestadores de Serviços") {        
            if (occupation === "Produtor Rural"){
            submitSpecificationToApi('Você é produtor rural em qual setor?', 'lmcx3uHBMM', email)
            }
            if (occupation === "Manufatura para o Agro"){
            submitSpecificationToApi('Qual o setor da Manufatura para o Agro?', 'P0yvV7Y3UM', email)
            }
            if (occupation === "Prestadores de Serviços"){
            submitSpecificationToApi('Você é prestador de serviços de qual setor?', 'aC7FZI9HnD', email)
            }
            if (occupation === "Agroindústria"){
            submitSpecificationToApi('Qual o setor da Agroindústria?', 'XGYemjmIab', email)
            }
            if (occupation === "Revenda"){
            submitSpecificationToApi('Qual o setor de revenda você atua?', `Zvfvh4Pxzy`, email)
            }
        }

        setFormStep('3')
        setLoading(false)
    } 

    async function handleToDatasValidator(e: any) {
        e.preventDefault()
        if (occupation === "" || occupation === "Selecione uma opção") {
        alert('Selecione uma opção')
        } else {
        if (!email || email === "") {
            alert("Email invalido")
        } else {
            SubmitDatasForApi(email)
        }
        }
    }

    function switchOptions (occupation: string) {
        switch (occupation) {
          case "Produtor Rural":
            return (
              <>
              <label>Qual se encaixa com você? *</label>
              <Select
                name="colors"
                placeholder="Selecione..."
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                
                onChange={(e: any) => {
                  setSpecification(e);
                }}
                options={prodRural}
                />
              </>
            )
         
          case "Manufatura para o Agro":
            return (
              <>
              <label>Qual se encaixa com você? *</label>
              <Select
                name="colors"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                
                onChange={(e: any) => {
                  setSpecification(e)
                  
                }}
                options={manuAgro}
              />
              </>
            )
            
            case "Revenda":
            return (
              <>
              <label>Qual se encaixa com você? *</label>
              <Select
                name="colors"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                
                onChange={(e: any) => {
                  setSpecification(e)
                  
                }}
                options={rev}
              />
              </>
            )
    
          case "Agroindústria":
          return (
            <>
            <label>Qual se encaixa com você? *</label>
            <Select
              name="colors"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              
              onChange={(e: any) => {
                setSpecification(e)
                
              }}
              options={agroInd}
            />
            </>
          )
          case "Prestadores de Serviços":
          return (
            <>
            <label>Qual se encaixa com você? *</label>
            <Select
              name="colors"
              placeholder="Selecione seu serviço"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              
              onChange={(e: any) => {
                setSpecification(e)
                
              }}
              options={prestServ}
            />
            </>
          )
          default:
            return ''
        }
    }

    return (
        <>
            {loading && (
                <div className="loading-container">
                    <div className="loading">
                    </div>
                    <p>Processando os dados</p>
                </div>
            )}
        {formStep === '1' && (
            <form 
                onSubmit={async e =>  {
                    e.preventDefault()
                    setLoading(true)
                    const result:string = await HandleSubmitOnFisrtStep({name, email, phone})
                    setFormStep(result)
                    setLoading(false)
                }} 
                className="form-container"
            >
                <h4>Preencha seus dados para participar</h4>
    
                <div className="field">
                    <label>Nome Completo *</label>
                    <input onChange={e => setName(e.target.value)} name="name" required placeholder="Digite aqui" />
                </div>
    
                <div className="field">
                    <label>Email *</label>
                    <input onChange={e => setEmail(e.target.value)} name="email" type="email" required placeholder="Seu melhor email" />
                </div>
    
                <div className="field">
                    <label>Telefone *</label>
                    <input onChange={e => setPhone(e.target.value)} placeholder="Digite seu número" required name="telefone"/>
                </div>
    
                <div className="field">
                    <input type="submit" value="Quero me inscrever" />
                </div>
            </form>
        )}
        {formStep === '2' && (
            <form
                onSubmit={e => {
                    setLoading(true)
                    handleToDatasValidator(e)
                }}
                className="form-container">
                <h4>Preencha seus dados para participar</h4>

                <div className="field">
                    <label>Ocupação *</label>
                    <select required onChange={e => {
                        setOccupation(e.target.value)
                        }}
                    >
                        {ocupacao.map((ocupetion, index) => {
                        return (
                            <option key={index}>{ocupetion}</option>
                        )
                        })}
                    </select>
                </div>

                <div className="field">        
                    {switchOptions(occupation)}
                </div>

                <div className="field">
                    <input type="submit" value="Quero me inscrever" />
                </div>
            </form>
        )}
        {formStep === '3' && (
            <form 
                onSubmit={e => {
                    handleToDatasValidatorFinalStep(e)
                }}
                className="form-container"
            >
                <h4>Preencha seus dados para participar</h4>

                <div className="field">
                    <label>Faixa de faturamento *</label>
                    <select onChange={e => {
                        setBillValue(e.target.value)
                        }}
                    >
                        {bill.map((bill, index) => {
                        return (
                            <option key={index}>{bill}</option>
                        )
                        })}
                    </select>
                </div>

                <div className="field go-back-group">
                    <input type="submit" value="Quero me inscrever" />
                </div>
            </form>
        )}
        {formStep === '4' && (
          <>
            <h1 className="title-confirm">Sua inscrição foi realizada com sucesso!</h1>
            <p className="p-confirm">Te convido a participar do grupo exclusivo no whatsapp para receber informações e conteúdos relacionados ao evento, topa?  </p>
            <a id="confirm-a" href="https://chat.whatsapp.com/CU250Gn65zGEVomDwFzTSv">
              <button className="btn-confirm">
                <img src={whatsappIcon} alt="" />
                Entrar no grupo
              </button>
            </a>
            <img className="confirm-image" src={confirmImage} alt="Sucesso" />
            </>
        )}
        </>
    )
}

export default Forms
