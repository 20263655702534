import React from 'react'

import './styles.css'

interface Card {
    image?: any,
    title: String,
    text: String
}

function Card(props: Card) {
    return (
        <article className="twb-card">
            <div className="twb-image-container">
                <img src={props.image} alt="" />
            </div>
            <h3>{props.title}</h3>
            <p>
                {props.text}
            </p>
      </article>
    )
}

export default Card
