import api from './api'

interface PropsFirstStep {
    name: String,
    email: String,
    phone: String
}

export async function HandleSubmitOnFisrtStep({name, email, phone}: PropsFirstStep): Promise<string> {
    await api({
        params: {
            jsoncallback: " ",
            token_api_lahar: "totvsagrijmmJFNL184O7n1dLqnLgGF3y4ZluUIcWAB72qlQcQ9RBYO70EcV7iLM",
            email_contato: email,
            nome_conversao: "BIOENERGIA | ETAPA 1",
            nome_contato: name,
            tel_celular: phone,
            tipo_integracao: "conversions",
            nome_formulario: "BIOENERGIA | ETAPA 1"
        }
    })
        .then(res => console.log('success', res))
        .catch(err => alert('Houve um erro ao salvar os dados, tente novamente. :('))

    return '2'
}

export const handleSendData = async (formName: string, fieldToken: string, value: string, email: string): Promise<string> => {
    console.log('entrado',value)
    await api({
        params: {
          jsoncallback: " ",
          token_api_lahar: "totvsagrijmmJFNL184O7n1dLqnLgGF3y4ZluUIcWAB72qlQcQ9RBYO70EcV7iLM",
          email_contato: email,
          nome_conversao: "BIOENERGIA | ETAPA 2 A",
          nome_formulario: "BIOENERGIA | ETAPA 2 A",
          tipo_integracao: "conversions",
          [fieldToken]: value
        }
      })
        .then(res => console.log('success'))
        .catch(err => alert('Houve um erro ao salvar os dados, tente novamente. :('))

        return '3'
}